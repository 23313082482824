import React from 'react'
import { Information } from 'ui/pages/Information'
import { InformationFilterQuery } from '../query'
import { useQuery } from '@apollo/client'
import { GenreProps, InformationProps } from '../types'
import { useParams } from 'react-router-dom'

type Variables = {
  sort?: {
    [key: string]: 1 | -1
  }
}

const Index: React.FC = () => {
  const { genre } = useParams<{
    genre?: string
  }>()

  const { loading, data } = useQuery<
    { informationList: InformationProps[]; genreList: GenreProps[] },
    Variables
  >(InformationFilterQuery, {
    variables: {
      sort: { updated: -1 },
    },
  })
  return !loading && data && data.informationList ? (
    <Information
      genre={{
        current: genre,
        list: data.genreList,
      }}
      information={data.informationList}
    />
  ) : null
}

export default Index
