import React from 'react'
import { Link } from 'react-router-dom'
import { InformationLinksProps } from 'app/types'
import { Chip, Grid } from '@material-ui/core/'
import { makeStyles, Theme } from '@material-ui/core/styles'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'

const useStyles = makeStyles((theme: Theme) => ({
  linkItem: {
    margin: theme.spacing(0.5, 0.5, 0.5, 0),
  },
}))

/**
 * ##出演情報個別コンテンツ
 */
export const InformationLInks: React.FC<InformationLinksProps> = ({
  talents,
  genre,
}) => {
  const classes = useStyles()
  return (
    <Grid item>
      {genre && (
        <Chip
          icon={<LocalOfferIcon />}
          size="small"
          key={genre.slug}
          label={genre.label}
          color="secondary"
          component={Link}
          to={`/information/genre/${genre.slug}`}
          className={classes.linkItem}
          clickable
        />
      )}
      {talents?.map(({ name, slug }) => (
        <Chip
          icon={<AccountCircleIcon />}
          size="small"
          key={slug}
          label={name}
          color="primary"
          component={Link}
          to={`/talent/${slug}`}
          className={classes.linkItem}
          clickable
        />
      ))}
    </Grid>
  )
}
