import React from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as MainLogo } from 'ui/images/main-logo.svg'
import { InformationSummaryItemProps, Setting, TalentList } from 'app/types'
import { Container } from 'ui/atom/Container'
import { TalentListItem } from 'ui/organisms/TalentListItem'
import { InformationSummaryItem } from 'ui/molecules/InformationSummaryItem'
import {
  Typography,
  Grid,
  List,
  ListItem,
  ButtonGroup,
  Button,
} from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { TwitterWidget } from 'ui/molecules/Twitter'
import { RoundBox } from 'ui/atom/RoundBox'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(3),
    },
  },
  title: {
    maxWidth: theme.spacing(112),
    padding: theme.spacing(5, 2, 3),
    margin: 'auto',
  },
  button: {
    minWidth: '14em',
    [theme.breakpoints.down('xs')]: {
      minWidth: '10em',
    },
  },
  subTitle: {
    maxWidth: theme.spacing(100),
    margin: 'auto',
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
    fontSize: theme.spacing(2.5),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.spacing(1.8),
      padding: theme.spacing(0, 2),
      textAlign: 'left',
      whiteSpace: 'normal',
    },
  },
  informationButton: {
    fontSize: '1rem',
    letterSpacing: '1em',
    padding: theme.spacing(0, 3),
    fontWeight: 'bold',
  },
  infoUl: { padding: 0 },
  infoLi: { padding: 0 },
}))

export interface HomeProps {
  talentList?: TalentList[]
  settings?: Setting
  informationList?: InformationSummaryItemProps[]
}
/**
 * ##トップページコンテンツ
 */
export const Home: React.FC<HomeProps> = ({
  talentList,
  settings,
  informationList,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography className={classes.title} component={'h1'}>
        <MainLogo title="イマジネイションロゴ" />
      </Typography>
      <Typography className={classes.subTitle} component={'h2'}>
        {settings?.copy}
      </Typography>
      <Container>
        <ButtonGroup variant="contained" color="primary">
          <Button component={Link} to={'/talents'} className={classes.button}>
            所属タレント一覧
          </Button>
          <Button
            component={Link}
            to={'/information'}
            className={classes.button}
          >
            出演情報・お知らせ一覧
          </Button>
        </ButtonGroup>
      </Container>
      <Container>
        {talentList?.map((talent) => (
          <TalentListItem
            small
            key={talent.slug}
            slug={talent.slug}
            name={talent.name}
            thumbnail={talent.thumbnail}
          />
        ))}
      </Container>
      <Container>
        <Grid container spacing={3}>
          <Grid item sm={8} xs={12}>
            {informationList && (
              <RoundBox
                title={
                  <Button
                    component={Link}
                    className={classes.informationButton}
                    to={'/information'}
                  >
                    最新情報
                  </Button>
                }
                color={'primary'}
              >
                <List className={classes.infoUl}>
                  {informationList?.map((info) => (
                    <ListItem key={info.title} className={classes.infoLi}>
                      <InformationSummaryItem {...info} />
                    </ListItem>
                  ))}
                </List>
              </RoundBox>
            )}
          </Grid>
          <Grid item sm={4} xs={12}>
            <TwitterWidget name={'imagination0602'} />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
