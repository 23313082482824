import React, { useEffect, useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import {
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  Typography,
  Link,
} from '@material-ui/core'
import { apiPath } from '../../app/config'

import { TalentSummaryItemProps } from 'app/types'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: theme.spacing(13 / 2, 13 / 2, 0, 0),
    background: 'transparent',
    margin: theme.spacing(0.5, 2),
    maxWidth: theme.spacing(13),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
    },
  },
  small: {
    margin: theme.spacing(0.25, 1.5),
    maxWidth: theme.spacing(10.5),
    borderRadius: theme.spacing(10.5 / 2, 10.5 / 2, 0, 0),
  },
  thumb: {
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(13 / 2),
  },
  placeholder: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    backgroundColor: '#eee',
    borderRadius: theme.spacing(13 / 2),
    margin: theme.spacing(0.25),
  },
  nameWrapper: {
    padding: 0,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  name: {
    flexGrow: 1,
    fontSize: theme.spacing(2),
    letterSpacing: 2,
  },
  smallName: {
    fontSize: theme.spacing(1.5),
    letterSpacing: 1,
  },
  item: {
    backgroundColor: theme.palette.background.paper,
  },
}))

/**
 * ##所属タレント一覧コンテンツ
 */
export const TalentListItem: React.FC<
  TalentSummaryItemProps & { small?: boolean }
> = ({ small, slug, thumbnail, name }) => {
  const classes = useStyles()

  const [imgPath, setImgPath] = useState<string>()

  useEffect(() => {
    if (thumbnail) {
      const img = new Image()
      const path = thumbnail?.path ? `${apiPath}/${thumbnail.path}` : ''
      img.src = path
      img.onload = () => {
        setImgPath(path)
      }
      img.onerror = () => {
        console.log('fail load image')
      }
    }
  }, [])

  return (
    <Link component={RouterLink} to={`/talent/${slug}`}>
      <Card
        className={clsx(classes.root, small && classes.small)}
        square
        elevation={0}
      >
        <CardActionArea>
          {imgPath ? (
            <CardMedia
              className={classes.thumb}
              component="img"
              src={imgPath}
              title={`${name} サムネイル`}
            />
          ) : (
            <div className={classes.placeholder} />
          )}
          <CardActions className={classes.nameWrapper}>
            <Typography
              variant={'h6'}
              component={'h3'}
              className={clsx(classes.name, small && classes.smallName)}
            >
              {name}
            </Typography>
          </CardActions>
        </CardActionArea>
      </Card>
    </Link>
  )
}
