import React from 'react'
import { HistoryItemProps, TalentProps, InformationProps } from 'app/types'
import { ProfileSection } from '../molecules/ProfileSection'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Chip, Grid, IconButton, Button } from '@material-ui/core/'
import TwitterIcon from '@material-ui/icons/Twitter'
import InstagramIcon from '@material-ui/icons/Instagram'
import YouTubeIcon from '@material-ui/icons/YouTube'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle'

import { apiPath } from '../../app/config'
import { HistoryItem } from '../molecules/HistoryItem'
import { TalentInformationItem } from '../molecules/TalentInformationItem'
import clsx from 'clsx'
import { Photos } from '../molecules/Photos'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4, 1),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(6, 2),
    },
  },
  name: {
    textAlign: 'center',
    margin: 0,
    letterSpacing: '0.25em',
    padding: theme.spacing(0, 1, 2),
  },
  roman: {
    display: 'block',
    fontSize: 14,
    color: theme.palette.text.secondary,
    padding: theme.spacing(0, 1),
  },
  profileThumbnail: {
    width: '100%',
    minHeight: 180,
    borderRadius: theme.spacing(2),
    border: `2px solid ${theme.palette.primary.light}`,
  },
  photos: {},
  photoItem: {},
  description: {
    whiteSpace: 'pre-wrap',
    fontFeatureSettings: `'palt' 1`,
    letterSpacing: '0.05em',
    padding: theme.spacing(1, 0),
    lineHeight: 1.5,
  },
  links: {
    padding: theme.spacing(0, 0, 3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  linkItem: {
    margin: theme.spacing(0, 0.5),
  },
  snsItem: {
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  voiceWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  voiceTitle: {
    color: theme.palette.secondary.light,
    fontSize: '0.5em',
    textTransform: 'uppercase',
    marginRight: theme.spacing(1),
  },
  player: {
    width: '100%',
    margin: theme.spacing(1, 0),
  },
  responsive: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  showXs: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  hideXs: {
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}))

/**
 * ##所属タレント個別コンテンツ
 */
export const Talent: React.FC<
  TalentProps & { information?: InformationProps[] }
> = ({
  name,
  roman,
  twitter,
  instagram,
  youtube,
  thumbnail,
  profile,
  photos,
  voice,
  pdf,
  tag,
  links,
  description,
  history,
  information,
}) => {
  const classes = useStyles()

  const historyItems: HistoryItemProps[] | undefined = history
    ?.split('\n\n')
    .map((string) => {
      const array = string.split('\n')
      const titleLine = array.shift()
      const title = titleLine?.replace('#', '').trim() || ''
      const lines = array
        .map((line) => {
          const [content, ...rest] = line.split(':')
          return {
            content,
            subContent: rest.join(':'),
          }
        })
        .filter((data) => !!data)
      return {
        title,
        lines,
      }
    })
    .filter(({ title }) => !!title)

  const names = (
    <>
      <h1 className={classes.name}>
        <div className={classes.responsive}>
          {name}
          {roman && <small className={classes.roman}>{roman}</small>}
        </div>
      </h1>
      <div className={classes.links}>
        {twitter && (
          <IconButton
            href={`https://twitter.com/${twitter}`}
            target="_blank"
            rel="noreferrer noopener"
            aria-label="twitter"
            className={clsx(classes.snsItem, classes.linkItem)}
          >
            <TwitterIcon />
          </IconButton>
        )}
        {instagram && (
          <IconButton
            href={`https://www.instagram.com/${twitter}`}
            target="_blank"
            rel="noreferrer noopener"
            aria-label="instagram"
            className={clsx(classes.snsItem, classes.linkItem)}
          >
            <InstagramIcon />
          </IconButton>
        )}
        {youtube && (
          <IconButton
            href={youtube}
            target="_blank"
            rel="noreferrer noopener"
            aria-label="instagram"
            className={clsx(classes.snsItem, classes.linkItem)}
          >
            <YouTubeIcon />
          </IconButton>
        )}
      </div>
      <div className={classes.responsive}>
        <div className={classes.links}>
          {tag?.map(({ label }) => (
            <Chip
              key={label}
              size="small"
              variant="outlined"
              icon={<LocalOfferIcon />}
              label={label}
              component="a"
              href={`/talents/tag/${encodeURI(label)}`}
              className={classes.linkItem}
              clickable
            />
          ))}
        </div>
        <div className={classes.links}>
          {links
            ?.map(({ value }) => {
              const [label, ...rest] = value?.split(':')
              return { label, url: rest.join(':') }
            })
            .map(({ label, url }) => (
              <Chip
                color="primary"
                key={label}
                icon={<PersonPinCircleIcon />}
                label={label}
                component="a"
                href={url}
                target="_blank"
                rel="noreferrer noopener"
                className={classes.linkItem}
                clickable
              />
            ))}
        </div>
      </div>
    </>
  )

  return (
    <div className={classes.root}>
      <Grid container justify="center" alignItems="flex-start" spacing={4}>
        <Grid item sm={5} md={3} lg={3}>
          <div className={classes.showXs}>{names}</div>
          {thumbnail?.path && (
            <img
              className={classes.profileThumbnail}
              src={`${apiPath}${thumbnail?.path}`}
              alt={`${name}プロフィール写真`}
            />
          )}
          {photos && !!photos.length && (
            <div className={classes.photos}>
              <Photos photos={photos} />
            </div>
          )}
          {description && (
            <div className={classes.description}>{description}</div>
          )}
          {profile && (
            <ProfileSection
              profiles={profile
                .split('\n')
                .filter((data) => !!data)
                .map((p) => {
                  const [label, ...rest] = p.split(':')
                  return { label, value: rest.join(':') }
                })}
            />
          )}
          {voice && (
            <div className={classes.voiceWrapper}>
              <h4 className={classes.voiceTitle}>voice</h4>
              <audio
                className={classes.player}
                controls
                src={`${apiPath}/${voice}`}
              >
                お使いのブラウザでは対応していないため表示されません。
              </audio>
            </div>
          )}
          {pdf && (
            <div>
              <Button
                fullWidth
                href={`${apiPath}/${pdf}`}
                variant="contained"
                color="primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                <PictureAsPdfIcon />
                資料向けPDF
              </Button>
            </div>
          )}
        </Grid>
        <Grid item sm={7} md={7} lg={6}>
          <div className={classes.hideXs}>{names}</div>
          {information?.length ? (
            <TalentInformationItem information={information} />
          ) : null}
          {historyItems?.map(({ title, lines }) => (
            <HistoryItem key={title} title={title} lines={lines} />
          ))}
        </Grid>
      </Grid>
    </div>
  )
}
