import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    minHeight: 'calc(100vh)',
  },
}))

export const MainWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const classes = useStyles()

  return <div className={classes.root}>{children}</div>
}
