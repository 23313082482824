import React from 'react'
import clsx from 'clsx'
import ReactMarkdown from 'react-markdown'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { ProfileProps } from 'app/types'

const useStyles = makeStyles((theme: Theme) => ({
  td: {
    padding: 0,
  },
  label: {
    whiteSpace: 'nowrap',
    paddingRight: theme.spacing(1.5),
  },
}))

/**
 * ##出演情報個別コンテンツ
 */
export const ProfileSection: React.FC<{ profiles: ProfileProps[] }> = ({
  profiles,
}) => {
  const classes = useStyles()
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {profiles.map(({ label, value }) => {
            return label ? (
              <TableRow key={label}>
                <TableCell
                  size="small"
                  className={clsx(classes.td, classes.label)}
                >
                  {label}
                </TableCell>
                <TableCell size="small" className={classes.td}>
                  <ReactMarkdown>{value}</ReactMarkdown>
                </TableCell>
              </TableRow>
            ) : null
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
