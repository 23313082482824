import React from 'react'
import { TagProps, TalentSummaryItemProps } from 'app/types'
import { Talents } from 'ui/pages/Talents'
import { useQuery } from '@apollo/client'
import { TalentsQuery } from '../query'
import { useHistory, useParams } from 'react-router-dom'

const Index: React.FC = () => {
  const history = useHistory()
  const { data } =
    useQuery<{ talentList: TalentSummaryItemProps[]; tags: TagProps[] }>(
      TalentsQuery,
    )
  const { tag } = useParams<{
    tag?: string
  }>()

  const selectTagHandler: (tag?: string) => void = (tag) => {
    tag
      ? history.push(`/talents/tag/${encodeURI(tag)}`)
      : history.push(`/talents`)
  }

  return data ? (
    <Talents
      items={data.talentList}
      tags={data.tags}
      tag={tag ? decodeURI(tag) : undefined}
      onSelectTag={selectTagHandler}
    />
  ) : null
}

export default Index
