import React from 'react'
import { TalentProps, InformationProps } from 'app/types'
import { useParams } from 'react-router-dom'
import { Talent } from 'ui/pages/Talent'
import { useQuery } from '@apollo/client'
import { talentSlugFilter } from '../query'

type Variables = {
  filter?: { slug: string }
  infoSort?: { [key: string]: number }
}

const Profile: React.FC = () => {
  const { talent_name } = useParams<{
    talent_name: string
  }>()

  const { loading, data } = useQuery<
    { talentList: TalentProps[]; informationList: InformationProps[] },
    Variables
  >(talentSlugFilter, {
    variables: {
      filter: {
        slug: talent_name,
      },
      infoSort: { _modified: -1 },
    },
  })

  if (loading) return null
  const talent = data?.talentList?.[0]
  const information = data?.informationList

  return talent ? (
    <Talent
      {...talent}
      information={information?.filter(
        ({ talents }) =>
          !!talents?.filter(
            ({ slug }) => !!talent_name && !!slug && slug === talent_name,
          ).length,
      )}
    />
  ) : null
}
export default Profile
