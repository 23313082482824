import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
const useStyles = makeStyles(() => ({
  closeButtonWrapper: { width: '100%' },
  closeButton: {
    top: -20,
    right: -20,
    backgroundColor: '#ddd',
    '&:hover': {
      backgroundColor: '#bbb',
    },
    position: 'absolute',
  },
}))

export const CloseButton: React.FC<{
  onClick: (e: React.MouseEvent<HTMLElement>) => void
}> = ({ onClick }) => {
  const classes = useStyles()

  return (
    <div className={classes.closeButtonWrapper}>
      <IconButton onClick={onClick} className={classes.closeButton}>
        <CloseIcon />
      </IconButton>
    </div>
  )
}
