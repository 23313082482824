import { apiPath, apiToken } from './config'

const collectionPath = `${apiPath}/api/collections/get/`
//const singletonPath = '/api/singleton/get/'

const token = apiToken

export type CollectionNames = 'talent' | 'TalentCategory' | 'information'
export type SingletonNames = 'About'

export default function getData(
  name: CollectionNames | SingletonNames,
  params?: object,
): Promise<any> {
  return postData(`${collectionPath}${name}`, { ...params, token })
}

async function postData(url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  return response.json()
}

export const getTalentSummary = () =>
  getData('talent', {
    fields: { slug: 1, name: 1, tags: 1 },
    sort: { order: 1 },
  })

export const getTalentInformation = (_id: string) =>
  getData('information', {
    filter: { published: true },
    fields: { 'talents.slug': 'takeiryosuke' },
  })

export const postInquiry = (params: object) =>
  postData(`${apiPath}/api/forms/submit/inquiry`, {
    ...params,
    token,
  })
