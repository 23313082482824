import React from 'react'
import { TalentSummaryProps } from 'app/types'
import { Container } from 'ui/atom/Container'
import { TalentListItem } from '../organisms/TalentListItem'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Chip } from '@material-ui/core'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import clsx from 'clsx'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tags: {
    display: 'flex',
    padding: theme.spacing(0, 2, 2),
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  disabledTag: {
    background: theme.palette.grey['400'],
    color: 'black',
  },
  wrapper: {
    width: '100%',
    maxWidth: 800,
    padding: theme.spacing(5, 5, 0),
  },
  links: {
    display: 'inline-block',
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
  },
}))
/**
 * ##所属タレント一覧コンテンツ
 */
export const Talents: React.FC<TalentSummaryProps> = ({
  items,
  tags,
  tag,
  onSelectTag,
}) => {
  const classes = useStyles()

  // タレントデータに存在するtag.labelの配列を生成
  const existingTags = items.filter((t) => !!t?.tag).map(({ tag }) => tag)
  let arr: string[] = []
  existingTags.forEach((tag) => {
    tag?.forEach((t) => t && arr.push(t.label))
  })
  // @ts-ignore
  const existingLabelArr: string[] = [...new Set(arr)]

  const tagArr = tags
    .filter((t) => existingLabelArr.includes(t.label))
    .map((t) => t.label)

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <h1>所属タレント</h1>
        <div className={classes.tags}>
          <span className={classes.links}>
            <Chip
              icon={<LocalOfferIcon />}
              variant="outlined"
              label="全て"
              clickable
              onClick={() => onSelectTag(undefined)}
              className={clsx(tag && classes.disabledTag)}
            />
          </span>
          {tagArr.map((label) => (
            <span className={classes.links}>
              <Chip
                icon={<LocalOfferIcon />}
                variant="outlined"
                label={label}
                clickable
                onClick={() => onSelectTag(label)}
                className={clsx(tag && tag !== label && classes.disabledTag)}
              />
            </span>
          ))}
        </div>
      </div>
      <Container>
        {items
          .filter((item) =>
            tag ? item.tag?.filter(({ label }) => label === tag).length : true,
          )
          .map((item) => (
            <TalentListItem key={item.slug} {...item} small />
          ))}
      </Container>
    </div>
  )
}
