import React from 'react'
import { Home } from 'ui/pages/Home'
import type { HomeProps } from 'ui/pages/Home'
import { HomeQuery } from '../query'
import { useQuery } from '@apollo/client'

const Index: React.FC = () => {
  const { data } = useQuery<HomeProps>(HomeQuery)
  return <Home {...data} />
}

export default Index
