import React from 'react'
import { About } from 'ui/pages/About'
import { SettingsQuery } from '../query'
import { useQuery } from '@apollo/client'

const Index: React.FC = () => {
  const { data } = useQuery<{ singleton: { about: string } }>(SettingsQuery)
  return data && data.singleton ? <About>{data.singleton.about}</About> : null
}

export default Index
