import React from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import ReactMarkdown from 'react-markdown'
import { ReactComponent as MainLogo } from 'ui/images/main-logo.svg'
import { Grid } from '@material-ui/core/'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 'auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.grey['200'],
  },
  logo: {
    width: 300,
    maxWidth: '80%',
    padding: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(0),
      margin: 'auto',
    },
  },
  content: {
    lineHeight: 1.5,
    fontSize: 12,
    padding: theme.spacing(1, 0),
    letterSpacing: '0.2em',
    display: 'flex',
    margin: 'auto',
  },
  footer: {
    textAlign: 'center',
    background: theme.palette.primary.light,
    lineHeight: 1.5,
    fontSize: 12,
    padding: theme.spacing(2, 3),
    letterSpacing: '0.25em',
  },
}))

/**
 * ##出演情報個別コンテンツ
 */
export const MainFooter: React.FC<{ content: string }> = ({ content }) => {
  const classes = useStyles()
  const now = new Date()
  return (
    <div className={classes.root}>
      <Grid container direction="row">
        <Typography className={classes.logo} variant={'h5'} component={'h4'}>
          <MainLogo title="イマジネイションロゴ" />
        </Typography>
        <div className={classes.content}>
          <ReactMarkdown>{content}</ReactMarkdown>
        </div>
      </Grid>

      <div className={classes.footer}>
        &copy;{now.getFullYear()} IMAGINATION
      </div>
    </div>
  )
}
