import { createTheme } from '@material-ui/core/styles'
import { lime, orange } from '@material-ui/core/colors'

export default createTheme({
  palette: {
    primary: {
      main: lime['A700'],
    },
    secondary: {
      main: orange[400],
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 28,
      },
      contained: {
        boxShadow: 'none',
      },
    },
    MuiButtonGroup: {
      root: {
        borderRadius: 28,
      },
      contained: {
        boxShadow: 'none',
      },
    },
  },
})
