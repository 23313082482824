import React, { useEffect } from 'react'
import { Contact, ContactContentProps } from 'ui/pages/Contact'
import { postInquiry } from '../api'

const Index: React.FC = () => {
  useEffect(() => {}, [])

  const onSubmitHandler = (data: ContactContentProps) =>
    postInquiry({
      form: data,
    })

  return <Contact onSubmit={onSubmitHandler} />
}

export default Index
