import React from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(0, 0.25, 0.25),
    borderRadius: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  colorPrimary: {
    backgroundColor: theme.palette.primary.light,
  },
  colorSecondary: {
    backgroundColor: theme.palette.secondary.light,
  },
  title: {
    padding: theme.spacing(0.75),
    margin: 0,
    letterSpacing: '1em',
    textAlign: 'center',
  },
  content: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1.75),
    padding: theme.spacing(0.5, 1.5),
  },
}))

/**
 * ##出演情報個別コンテンツ
 */
export const RoundBox: React.FC<{
  title: React.ReactNode
  children: React.ReactNode
  color: 'primary' | 'secondary'
}> = ({ title, children, color }) => {
  const classes = useStyles()
  return (
    <div
      className={clsx(
        classes.root,
        color === 'secondary' ? classes.colorSecondary : classes.colorPrimary,
      )}
    >
      {title && <h3 className={classes.title}>{title}</h3>}
      <div className={classes.content}>{children}</div>
    </div>
  )
}
