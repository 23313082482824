import React from 'react'
import ReactMarkdown from 'react-markdown'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(5),
    maxWidth: theme.spacing(80),
    margin: 'auto',
  },
  content: {
    lineHeight: 1.8,
  },
}))

export interface AboutProps {
  children: any
}

/**
 * ##会社概要コンテンツ
 */
export const About: React.FC<AboutProps> = ({ children }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <h1>会社概要</h1>
      <div className={classes.content}>
        <ReactMarkdown>{children}</ReactMarkdown>
      </div>
    </div>
  )
}
