import React, { useEffect, useState } from 'react'
import { Link } from '@material-ui/core'

export const TwitterWidget: React.FC<{ name: string }> = ({ name }) => {
  const [isScriptInit, setIsScriptInit] = useState<boolean>(false)
  useEffect(() => {
    const s: HTMLScriptElement = document.createElement('script')
    if (!isScriptInit) {
      s.setAttribute('src', 'https://platform.twitter.com/widgets.js')
      s.async = true
      document.body.appendChild(s)
      setIsScriptInit(true)
    }
    return () => {
      document.body.removeChild(s)
    }
  }, [])
  return isScriptInit ? (
    <Link
      className="twitter-timeline"
      data-height="1200"
      href={`https://twitter.com/${name}?ref_src=twsrc%5Etfw`}
    >
      Tweets by {name}
    </Link>
  ) : null
}
