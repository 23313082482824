import React, { useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Button, Grid, Modal, Typography, TextField } from '@material-ui/core/'
import { CloseButton } from '../atom/CloseButton'

import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import yupLocale from 'ui/yupLocale'
yupLocale()

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(5),
    maxWidth: theme.spacing(80),
    margin: 'auto',
    justifyContent: 'center',
  },
  content: {
    lineHeight: 1.8,
  },
  modalContentWrapper: {
    height: '100%',
    margin: 'auto',
    width: 800,
    maxWidth: '100vw',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    width: '100%',
    maxWidth: 400,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    borderRadius: theme.spacing(2),
  },
}))

export type ContactContentProps = {
  name?: string
  title?: string
  email?: string
  phone?: string
  content?: string
  error?: boolean
}

export interface ContactProps {
  onSubmit: (data: ContactContentProps) => Promise<void>
}

const schema = yup
  .object()
  .shape({
    name: yup.string().max(100).required(),
    title: yup.string().max(100).nullable(),
    email: yup.string().email().required(),
    phone: yup
      .string()
      .trim()
      .max(13)
      .matches(/^0(\d{1,4}-\d{1,4}-\d{3,4}|\d{9,10})$/, {
        message: '半角英数字、正しい桁数で入力してください',
        excludeEmptyString: true,
      })
      .nullable(),
    content: yup.string().max(1200).required(),
  })
  .required()

const defaultValues = {
  name: '',
  title: '',
  email: '',
  phone: '',
  content: '',
}

/**
 * ##会社概要コンテンツ
 */
export const Contact: React.FC<ContactProps> = ({ onSubmit }) => {
  const classes = useStyles()

  const [modal, setModal] = useState<ContactContentProps | undefined>(undefined)
  const [sending, setSending] = useState<boolean>(false)

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const onSubmitHandler = () => {
    setSending(true)

    handleSubmit((data) =>
      onSubmit(data as ContactContentProps)
        .then(() => {
          setTimeout(() => {
            setModal(data as ContactContentProps)
            reset(defaultValues)
          }, 200)
        })
        .catch(() => setModal({ error: true }))
        .finally(() => {
          setTimeout(() => setSending(false), 3000)
        }),
    )()
  }

  const closeModalHandler = () => setModal(undefined)

  return (
    <div className={classes.root}>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <h1>お問い合わせ</h1>
          <div>
            下記フォーム以外からのお問い合わせはmail@imagination-tv.comまでどうぞ。
            <br />
            （フォームからのお問い合わせもこちらのアドレスに送信されます。）
          </div>
        </Grid>
        <Grid item>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                label="お名前"
                color="secondary"
                variant="filled"
                fullWidth
                error={!!errors?.name}
                helperText={errors?.name?.message}
              />
            )}
            control={control}
            name="name"
          />
        </Grid>
        <Grid item>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                label="お問い合わせされる件名（任意）"
                color="secondary"
                variant="filled"
                fullWidth
                placeholder="件名:"
                error={!!errors?.title}
                helperText={errors?.title?.message}
              />
            )}
            control={control}
            name="title"
          />
        </Grid>
        <Grid item>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                label="メールアドレス"
                variant="filled"
                color="secondary"
                placeholder="email:"
                fullWidth
                error={!!errors?.email}
                helperText={errors?.email?.message}
              />
            )}
            control={control}
            name="email"
          />
        </Grid>
        <Grid item>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                label="お電話番号（任意）"
                color="secondary"
                variant="filled"
                placeholder="000-0000-0000"
                fullWidth
                error={!!errors?.phone}
                helperText={errors?.phone?.message}
              />
            )}
            control={control}
            name="phone"
          />
        </Grid>
        <Grid item>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                label="お問い合わせ内容"
                color="secondary"
                variant="filled"
                placeholder="本文:"
                multiline
                rows={4}
                fullWidth
                error={!!errors?.content}
                helperText={errors?.content?.message}
              />
            )}
            control={control}
            name="content"
          />
        </Grid>

        <Grid item>
          <Grid container justifyContent="center">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={onSubmitHandler}
                disabled={sending || !!modal || !isValid}
                size="large"
              >
                送信する
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Modal open={!!modal} onClose={closeModalHandler}>
        <div
          className={classes.modalContentWrapper}
          onClick={() => setModal(undefined)}
        >
          <div className={classes.modalContent}>
            <CloseButton onClick={closeModalHandler} />
            <Grid container direction="column" spacing={3}>
              {modal?.error ? (
                <>
                  <Grid item>
                    <h3>
                      送信に失敗しました。
                      <br />
                      再度送信ボタンを押してください。
                    </h3>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item>
                    <h3>以下の内容で送信されました。</h3>
                  </Grid>
                  <Grid item>
                    <h4>お名前</h4>
                    <Typography component={'p'}>{modal?.name}</Typography>

                    {modal?.title && (
                      <>
                        <h4>件名</h4>
                        <Typography component={'p'}>{modal?.title}</Typography>
                      </>
                    )}
                    <>
                      <h4>E-mail</h4>
                      <Typography component={'p'}>{modal?.email}</Typography>
                    </>
                    {modal?.phone && (
                      <>
                        <h4>お電話番号</h4>
                        <Typography component={'p'}>{modal?.phone}</Typography>
                      </>
                    )}
                    <h4>お問合せ内容</h4>
                    <Typography component={'p'}>{modal?.content}</Typography>
                  </Grid>
                  <Grid item container>
                    <Grid container justifyContent="center">
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={closeModalHandler}
                        >
                          閉じる
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        </div>
      </Modal>
    </div>
  )
}
