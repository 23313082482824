import 'fontsource-roboto'
import { ThemeProvider, createTheme } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'
import theme from 'ui/theme'
import Routes from 'app/Routes'
import { ApolloProvider } from '@apollo/client'
import { client } from './client'

function App() {
  return (
    <ThemeProvider theme={createTheme(theme)}>
      <ApolloProvider client={client}>
        <CssBaseline />
        <Routes />
      </ApolloProvider>
    </ThemeProvider>
  )
}

export default App
