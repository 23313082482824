import React from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    width: '100%',
    maxWidth: theme.spacing(160),
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: theme.spacing(3, 3),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(3, 5),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
}))

export const Container: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.container}>{children}</div>
    </div>
  )
}
