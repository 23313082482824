import React from 'react'
import { Link as Router } from 'react-router-dom'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Link } from '@material-ui/core'
import clsx from 'clsx'
import HomeIcon from '@material-ui/icons/Home'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    position: 'fixed',
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 1000,
  },
  home: {
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0, 0, 2.5, 0),
    background: theme.palette.primary.main,
  },
  isHome: { opacity: 0, width: 0 },
  items: {
    borderRadius: theme.spacing(0, 0, 0, 2.5),
    padding: theme.spacing(1, 0, 1.5, 1.75),
    background: theme.palette.primary.main,
  },
  item: {
    fontSize: theme.spacing(1.5),
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(0, 1),
  },
  isCurrent: { fontWeight: 'bold' },
  hide: { display: 'none' },
}))

export type NavProps = {
  path: string
  label: React.ReactNode
  hidePc?: boolean
}

export interface MainNavProps {
  current?: string
  home: NavProps
  nav: NavProps[]
}

/**
 * ##メインヘッダナビゲーション
 */
export const MainNav: React.FC<MainNavProps> = ({ current, home, nav }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={clsx(classes.home, current === '/' && classes.isHome)}>
        <Link component={Router} to={home.path} className={classes.item}>
          <HomeIcon />
        </Link>
      </div>
      <div className={classes.items}>
        {nav.map((n, index) => (
          <Link
            component={Router}
            to={n.path}
            className={clsx(
              classes.item,
              current?.startsWith(n.path) && classes.isCurrent,
              n.hidePc && classes.hide,
            )}
            key={`${n.label}${index}`}
          >
            {n.label}
          </Link>
        ))}
      </div>
    </div>
  )
}
