import { gql } from '@apollo/client'

export const settingsFragment = gql`
  fragment settingsFragment on settingsSingleton {
    copy
  }
`

export const genreListFragment = gql`
  fragment genreListFragment on genreCollection {
    label
    slug
    order
  }
`

export const talentListFragment = gql`
  fragment talentListFragment on talentCollection {
    slug
    name
    thumbnail {
      path
    }
  }
`

export const talentsFragment = gql`
  fragment talentsFragment on talentCollection {
    slug
    name
    tag {
      label
    }
    thumbnail {
      path
    }
  }
`

export const tagsFragment = gql`
  fragment tagsFragment on tagCollection {
    label
  }
`

export const informationFragment = gql`
  fragment informationListFragment on informationCollection {
    title
    summary
    content
    talents {
      name
      slug
    }
    genre {
      label
      slug
    }
    updated
    _id
    _modified
  }
`

export const informationListFragment = gql`
  fragment informationListFragment on informationCollection {
    title
    summary
    content
    talents {
      name
      slug
    }
    genre {
      label
      slug
    }
    updated
    _id
    _modified
  }
`

export const InformationFilterQuery = gql`
  query talent($filter: JsonType, $sort: JsonType) {
    genreList: genreCollection(filter: $filter) {
      ...genreListFragment
    }
    informationList: informationCollection(filter: $filter, sort: $sort) {
      ...informationListFragment
    }
  }
  ${informationListFragment}
  ${genreListFragment}
`

export const talentProfileFragment = gql`
  fragment talentListFragment on talentCollection {
    slug
    name
    roman
    profile
    description
    history
    twitter
    instagram
    youtube
    photos {
      path
    }
    links {
      value
    }
    tag {
      label
    }
    thumbnail {
      path
    }
    pdf
    voice
  }
`

export const talentSlugFilter = gql`
  query talent($filter: JsonType, $infoSort: JsonType) {
    talentList: talentCollection(filter: $filter) {
      ...talentListFragment
    }
    informationList: informationCollection(
      filter: { publish: "Published" }
      sort: $infoSort
    ) {
      ...informationListFragment
    }
  }
  ${talentProfileFragment}
  ${informationListFragment}
`

export const HomeQuery = gql`
  query home {
    settings: settingsSingleton {
      ...settingsFragment
    }
    talentList: talentCollection(sort: { order: 1 }) {
      ...talentListFragment
    }
    informationList: informationCollection(
      filter: { news: true, publish: "Published" }
      sort: { updated: -1 }
    ) {
      ...informationListFragment
    }
  }
  ${settingsFragment}
  ${talentListFragment}
  ${informationListFragment}
`

export const TalentsQuery = gql`
  query {
    talentList: talentCollection(sort: { order: 1 }) {
      ...talentsFragment
    }
    tags: tagCollection {
      ...tagsFragment
    }
  }
  ${talentsFragment}
  ${tagsFragment}
`

export const SettingsQuery = gql`
  {
    singleton(name: "settings")
  }
`
