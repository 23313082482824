import { setLocale } from 'yup'

export default () => {
  setLocale({
    mixed: {
      required: '入力してください',
      notType: ({ type }: { type: any }) =>
        type === 'number'
          ? '数字を入力してください'
          : '正しい形式を入力してください',
    },
    string: {
      min: '${min}文字以上にしてください',
      max: '${max}文字以内にしてください',
      length: '${length}文字入力してください',
      email: '正しい形式にしてください',
      url: '正しい形式にしてください',
    },
    number: {
      min: '${min}以上の値を入力してください',
      max: '${max}以下の値を入力してください',
      integer: '整数を入力してください',
    },
  })
}
