import React, { useState } from 'react'

import { makeStyles, Theme } from '@material-ui/core/styles'
import { InformationProps } from 'app/types'
import { RoundBox } from '../atom/RoundBox'
import { Chip, Modal, Typography } from '@material-ui/core'
import { InformationModalContent } from './InformationSummaryItem'

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'top',
    flexWrap: 'wrap',
    padding: theme.spacing(0.5, 0),
    borderBottom: '1px dashed #ccc',
    marginBottom: theme.spacing(1),
    '&:last-child': {
      borderBottom: 'none',
      marginBottom: 0,
    },
    position: 'relative',
    justifyContent: 'space-between',
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  title: {
    lineHeight: 1.8,
    paddingRight: theme.spacing(1),
    fontSize: '1.25em',
  },
  links: { display: 'inline-block', paddingRight: theme.spacing(1) },
  summary: {
    whiteSpace: 'pre-wrap',
    color: theme.palette.text.secondary,
    padding: theme.spacing(0.25, 0),
    lineHeight: 1.8,
  },
  more: {
    margin: theme.spacing(1, 0, 1, 'auto'),
  },
}))

export const TalentInformationItem: React.FC<{
  information: InformationProps[]
}> = ({ information }) => {
  const classes = useStyles()
  const [modalIndex, setModalIndex] = useState<number | undefined>()
  return (
    <RoundBox title={'最新情報'} color={'primary'}>
      {information.map(
        ({ title, summary, genre, content, talents, updated }, index) => (
          <div className={classes.wrapper}>
            <div className={classes.content}>
              <Typography
                className={classes.title}
                variant={'h5'}
                component={'h2'}
                gutterBottom
              >
                {genre && (
                  <span className={classes.links}>
                    <Chip
                      variant="outlined"
                      size="small"
                      label={genre.label}
                      clickable
                    />
                  </span>
                )}
                {title}
              </Typography>
              <Typography
                className={classes.summary}
                variant="body1"
                component={'p'}
                gutterBottom
              >
                {summary}
              </Typography>
            </div>
            {content && (
              <Chip
                className={classes.more}
                clickable
                label="詳細"
                size="small"
                onClick={() => setModalIndex(index)}
              />
            )}
            {content && (
              <Modal
                open={modalIndex === index}
                onClose={() => setModalIndex(undefined)}
              >
                <InformationModalContent
                  title={title}
                  summary={summary}
                  talents={talents}
                  genre={genre}
                  content={content}
                  updated={updated}
                  onClose={() => setModalIndex(undefined)}
                />
              </Modal>
            )}
          </div>
        ),
      )}
    </RoundBox>
  )
}
