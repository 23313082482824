import React from 'react'
import { PhotoProps } from 'app/types'

import { makeStyles, Theme } from '@material-ui/core/styles'
import { apiPath } from '../../app/config'
import { Chip, Modal, Button, ButtonGroup } from '@material-ui/core'
import { CloseButton } from '../atom/CloseButton'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    color: theme.palette.secondary.light,
    fontSize: '0.5em',
    textTransform: 'uppercase',
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(1),
  },
  items: {
    padding: theme.spacing(1, 0.25),
  },
  modalWrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  imgWrapper: {
    maxWidth: `calc(100% - 80px)`,
    maxHeight: `calc(100% - 80px)`,
    position: 'relative',
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: theme.spacing(2),
    border: `2px solid ${theme.palette.primary.light}`,
  },
  buttonWrapper: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    textAlign: 'center',
  },
}))

/**
 * ##出演情報個別コンテンツ
 */
export const Photos: React.FC<{ photos: PhotoProps[] }> = ({ photos }) => {
  const classes = useStyles()
  const [img, setImg] = React.useState<{
    src: string
    index: number
  } | null>(null)

  const isStart: boolean = !!(img && img.index === 0)
  const isEnd: boolean = photos.length - 1 === (img && img.index)

  const closeHandler = () => setImg(null)

  const onClickButton = (e: React.MouseEvent<HTMLElement>, add: number) => {
    if (!img) return
    const index = img?.index + add
    const { path } = photos[index]
    setImg({
      index,
      src: `${apiPath}/${path}`,
    })
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <div className={classes.root}>
      <h4 className={classes.title}>photos</h4>
      {photos.map(({ path }, index) => (
        <div key={path} className={classes.items}>
          <Chip
            label={index + 1}
            onClick={() =>
              setImg({
                src: `${apiPath}/${path}`,
                index,
              })
            }
            clickable
          />
        </div>
      ))}
      <Modal open={!!img} onClose={closeHandler}>
        <div className={classes.modalWrapper} onClick={closeHandler}>
          <div className={classes.imgWrapper}>
            <div className={classes.buttonWrapper}>
              <ButtonGroup variant="contained" size="small">
                <Button
                  disabled={isStart}
                  onClick={(e: React.MouseEvent<HTMLElement>) =>
                    onClickButton(e, -1)
                  }
                >
                  ＜
                </Button>
                <Button
                  disabled={isEnd}
                  onClick={(e: React.MouseEvent<HTMLElement>) =>
                    onClickButton(e, 1)
                  }
                >
                  ＞
                </Button>
              </ButtonGroup>
            </div>
            <CloseButton onClick={closeHandler} />
            <img
              src={img?.src}
              className={classes.img}
              alt={`写真${(img?.index || 0) + 1}`}
            />
          </div>
        </div>
      </Modal>
    </div>
  )
}
