import React from 'react'
import { Link } from 'react-router-dom'
import { GenreProps, InformationProps } from 'app/types'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { InformationSummaryItem } from '../molecules/InformationSummaryItem'
import { Chip } from '@material-ui/core'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(5),
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapper: {
    width: '100%',
    maxWidth: 800,
  },
  item: {
    padding: theme.spacing(1, 0, 4),
  },
  linkItem: {
    margin: theme.spacing(0.5, 0.5, 0.5, 0),
  },
}))

/**
 * ##出演情報・お知らせページコンテンツ
 */
export const Information: React.FC<{
  information: InformationProps[]
  genre?: {
    current?: string
    list: GenreProps[]
  }
}> = ({ information, genre }) => {
  const classes = useStyles()

  let existingGenreArray = information
    .filter(({ genre }) => !!(genre && genre.slug))
    .map(({ genre }) => genre && genre.slug)
  let existingGenre = new Set(existingGenreArray)
  existingGenreArray = Array.from(existingGenre)

  const ifFiltered = genre && genre.current

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <h1>出演情報・お知らせ</h1>
        <div>
          <Chip
            icon={<LocalOfferIcon />}
            label={'全て'}
            color={ifFiltered ? 'default' : 'secondary'}
            component={Link}
            to={`/information`}
            className={classes.linkItem}
            clickable
          />
          {genre?.list
            .filter(({ slug }) => existingGenreArray.includes(slug))
            .map(({ slug, label }) => (
              <Chip
                icon={<LocalOfferIcon />}
                key={slug}
                label={label}
                color={
                  ifFiltered
                    ? genre.current === slug
                      ? 'secondary'
                      : 'default'
                    : 'secondary'
                }
                component={Link}
                to={`/information/genre/${slug}`}
                className={classes.linkItem}
                clickable
              />
            ))}
        </div>
        {information
          .filter((item) =>
            ifFiltered
              ? item.genre &&
                item.genre.slug &&
                item.genre.slug === genre.current
              : true,
          )
          .map((info) => (
            <div key={info._id} className={classes.item}>
              <InformationSummaryItem {...info} />
            </div>
          ))}
      </div>
    </div>
  )
}
