import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { MainNav } from 'ui/organisms/MainNav'
import { pageSettings } from './Routes'

const Nav: React.FC = () => {
  const [current, setCurrent] = useState<string>('/')
  const { pathname } = useLocation()

  useEffect(() => {
    setCurrent(pathname)
  }, [pathname])

  return (
    <MainNav
      current={current}
      home={{ path: '/', label: 'Home' }}
      nav={pageSettings
        .filter((s) => !!s.label)
        .map(({ path, label, hidePc }) => ({ path, label, hidePc }))}
    />
  )
}

export default Nav
