import React, { memo } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Nav from 'app/Nav'
import Home from 'app/Home'
import About from 'app/About'
import Contact from 'app/Contact'
import Information from 'app/Informaion'
import Talents from 'app/Talent'
import TalentProfile from 'app/Talent/profile'
import { SettingsQuery } from 'app/query'
import { useQuery } from '@apollo/client'
import { MainFooter } from '../ui/organisms/MainFooter'
import { MainWrapper } from '../ui/atom/MainWrapper'
// import { useTracking } from './useTracking'
// import { ga4Id } from './config'

export const pageSettings = [
  { path: '/information', component: Information },
  { path: '/information/genre/:genre', component: Information },
  { path: '/talent/:talent_name', component: TalentProfile, hidePc: true },
  { path: '/talents', label: 'Talents', component: Talents, hidePc: true },
  {
    path: '/talents/tag/:tag',
    label: 'Talents',
    component: Talents,
    hidePc: true,
  },
  { path: '/about', label: '会社概要', component: About },
  { path: '/contact', label: 'お問い合わせ', component: Contact },
]
const Routes: React.FC = memo(() => {
  //  useTracking(ga4Id)
  const { data } = useQuery<{ singleton: { footer: string } }>(SettingsQuery)
  return (
    <BrowserRouter>
      <MainWrapper>
        <Nav />
        <Switch>
          <Route exact path={'/'} component={Home} />
          {pageSettings.map(({ path, component }) => (
            <Route key={path} exact path={path} component={component} />
          ))}
          <Route exact path={'*'} component={Home} />
        </Switch>
        {data && <MainFooter content={data.singleton.footer} />}
      </MainWrapper>
    </BrowserRouter>
  )
})
export default Routes
