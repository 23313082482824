import React from 'react'
import ReactMarkdown from 'react-markdown'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { HistoryItemProps } from 'app/types'
import { RoundBox } from '../atom/RoundBox'

const useStyles = makeStyles((theme: Theme) => ({
  line: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(0.5, 0),
    borderBottom: '1px dashed #ccc',
    '&:last-child': {
      borderBottom: 'none',
    },
    '& p': { margin: 0 },
    '& blockquote': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  content: {
    lineHeight: 1.8,
    paddingRight: theme.spacing(1),
  },
  subContent: {
    color: theme.palette.text.secondary,
    lineHeight: 1.8,
  },
}))

export const HistoryItem: React.FC<HistoryItemProps> = ({ title, lines }) => {
  const classes = useStyles()
  return (
    <RoundBox title={title} color={'secondary'}>
      {lines?.map(({ content, subContent }) => (
        <div key={content} className={classes.line}>
          {content && (
            <div className={classes.content}>
              <ReactMarkdown>{content.trim()}</ReactMarkdown>
            </div>
          )}
          {subContent && (
            <div className={classes.subContent}>
              <ReactMarkdown>{subContent.trim()}</ReactMarkdown>
            </div>
          )}
        </div>
      ))}
    </RoundBox>
  )
}
