import React, { useState } from 'react'
import { InformationSummaryItemProps } from 'app/types'
import { Chip, Divider, Grid, Modal, Typography } from '@material-ui/core/'
import { makeStyles, Theme } from '@material-ui/core/styles'
import ReactMarkdown from 'react-markdown'

import { InformationLInks } from './InformationLinks'
import { CloseButton } from '../atom/CloseButton'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    margin: theme.spacing(1, 0.5, 1.5),
  },
  summary: {
    whiteSpace: 'pre-wrap',
    color: theme.palette.text.secondary,
  },
  updated: {
    color: theme.palette.grey.A200,
    position: 'absolute',
    right: 0,
    top: theme.spacing(0.2),
    fontSize: 10,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      textAlign: 'right',
    },
  },
  title: {
    position: 'relative',
    padding: theme.spacing(0.75, 0, 0.5),
  },
  modalContentWrapper: {
    height: '100%',
    margin: 'auto',
    maxWidth: '100vw',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    width: 700,
    maxWidth: '90vw',
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
  },
  content: {
    padding: 0,
  },
  links: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  more: {
    display: 'flex',
    alignItems: 'center',
  },
}))

/**
 * ##出演情報個別コンテンツ
 */
export const InformationSummaryItem: React.FC<InformationSummaryItemProps> = ({
  title,
  summary,
  talents,
  genre,
  content,
  updated,
}) => {
  const classes = useStyles()
  const [modal, setModal] = useState<InformationSummaryItemProps>()
  return (
    <div className={classes.root}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography component={'p'} className={classes.summary}>
            {summary}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Typography component={'h3'} variant={'h6'} className={classes.title}>
        {updated && <div className={classes.updated}>updated: {updated}</div>}
        {title}
      </Typography>
      <div className={classes.links}>
        <InformationLInks genre={genre} talents={talents} />
        {content && (
          <Chip
            clickable
            label="詳細を見る"
            onClick={() =>
              setModal({
                title,
                summary,
                talents,
                genre,
                content,
              })
            }
          />
        )}
      </div>
      {content && (
        <Modal open={!!modal} onClose={() => setModal(undefined)}>
          <InformationModalContent
            title={title}
            summary={summary}
            talents={talents}
            genre={genre}
            content={content}
            updated={updated}
            onClose={() => setModal(undefined)}
          />
        </Modal>
      )}
    </div>
  )
}

export const InformationModalContent: React.FC<
  InformationSummaryItemProps & { onClose: () => void }
> = ({ onClose, title, summary, talents, genre, content, updated }) => {
  const classes = useStyles()
  return (
    <div className={classes.modalContentWrapper} onClick={onClose}>
      <div className={classes.modalContent}>
        <CloseButton onClick={onClose} />
        <Grid container>
          <Grid item>
            <Typography component={'p'} className={classes.summary}>
              {summary}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Typography component={'h3'} variant={'h6'} className={classes.title}>
          {updated && <div className={classes.updated}>updated: {updated}</div>}
          {title}
        </Typography>
        <InformationLInks genre={genre} talents={talents} />
        {content && (
          <div className={classes.content}>
            <ReactMarkdown>{content}</ReactMarkdown>
          </div>
        )}
      </div>
    </div>
  )
}
